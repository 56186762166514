//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseToast from './BaseToast.vue';

export default {
    components: {
        BaseToast,
    },

    methods: {
        onClickAgree() {
            this.$cookies.set('cookie-consent-user-accepted', 'true', {
                maxAge: 60 * 60 * 24 * 180, // 6 months
            });

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
    },
};
