//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    model: {
        prop: 'visible',
        event: 'hidden',
    },

    props: {
        visible: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: undefined,
            validator: (value) => [undefined, 'secondary', 'success', 'danger'].includes(value),
        },
        toastClass: {
            type: String,
            default: null,
        },
        noAutoHide: {
            type: Boolean,
            default: false,
        },
    },

    destroyed() {
        this.$emit('destroyed', this.$vnode.key);
    },

    mounted() {
        if (!this.noAutoHide) this.attachAutoHide();
    },

    methods: {
        attachAutoHide() {
            setTimeout(() => {
                this.$destroy();
                this.$el.parentNode.removeChild(this.$el);
            }, 5000);
        },
    },
};
