import { render, staticRenderFns } from "./ToastGdpr.vue?vue&type=template&id=5b7fb89e&"
import script from "./ToastGdpr.vue?vue&type=script&lang=js&"
export * from "./ToastGdpr.vue?vue&type=script&lang=js&"
import style0 from "./ToastGdpr.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseToast: require('/home/runner/work/cabral.ro/cabral.ro/components/BaseToast.vue').default})
